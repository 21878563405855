
import { Vue, Component } from 'vue-property-decorator';
import { Auth } from '@/store/modules';

@Component({
  name: "StartEducating",
})
export default class extends Vue {
  loading = false;

  async submit() {
    this.loading = true;
    try {
      await Auth.updateAccount({
        role: 'teacher'
      });
      this.$router.push('/public-account');
    } catch (err) {
      window.alert('Failed to create teacher account. Please try again later.')
    }
    this.loading = false;
  }
}
